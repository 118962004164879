import slick from 'slick-carousel';

$('.banners').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    appendDots: $('.dots-banners'),
    prevArrow: $('.banner-prev'),
    nextArrow: $('.banner-next'),
    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        },
    },
    {
        breakpoint: 784,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    ],
});

$('.center').slick({
    centerMode: true,
    centerPadding: '250px',
    slidesToShow: 3,
    focusOnSelect: true,
    dots: false,
    infinite: true,
    prevArrow: $('.center-prev'),
    nextArrow: $('.center-next'),
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerPadding: '100px',
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            },
        },
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        }
    ]
});

